import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useArticleQuery = () => {
  const { allSanityArticle } = useStaticQuery(graphql`
    query {
      allSanityArticle(sort: { fields: date, order: DESC }) {
        articles: nodes {
          title
          slug {
            current
          }
          date(formatString: "DD MMMM YYYY")
          intro
          body: _rawBody(resolveReferences: { maxDepth: 10 })
          featuredImage {
            ...ImageWithPreview
          }
        }
      }
    }
  `);
  return allSanityArticle || {};
};
